import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { rgba } from 'polished'
import { NavLink as RouterNavLink, withRouter } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import '../vendor/perfect-scrollbar.css'
import {
    Chip,
    Grid,
    Collapse,
    Drawer as MuiDrawer,
    List as MuiList,
    ListItem,
    ListItemText,
    Typography,
} from '@material-ui/core'
import Badge from '@material-ui/core/Badge'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { sidebarRoutes as routes, sidebarRoutesOwner } from '../routes/index'
import logo from '../logo.png'
import StateContext from '../services/contextService'
import { parseRole } from 'globalFunctions/globalFunctions'
import { manageSectionActions } from 'globalFunctions/permissions'
import SelectAccount from 'components/management/SelectAccount'
import { managePermissions } from 'pages/pages/account/permisions/permisions'
import { roles } from 'constants/common'

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />)

const Drawer = styled(MuiDrawer)`
    border-right: 0;

    > div {
        border-right: 0;
    }
`

const Scrollbar = styled(PerfectScrollbar)`
    background: ${props => props.theme.sidebar.background};
    border-right: 1px solid rgba(0, 0, 0, 0.12);
`

const List = styled(MuiList)`
    background-color: ${props => props.theme.sidebar.background};
`

const Items = styled.div`
    padding-top: ${props => props.theme.spacing(2.5)}px;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
`

const Brand = styled(ListItem)`
    font-size: ${props => props.theme.typography.h5.fontSize};
    font-weight: ${props => props.theme.typography.fontWeightMedium};
    color: ${props => props.theme.sidebar.header.color};
    background-color: ${props => props.theme.sidebar.header.background};
    font-family: ${props => props.theme.typography.fontFamily};
    min-height: 56px;
    cursor: default;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid;
    ${props => props.theme.breakpoints.up('sm')} {
        min-height: 64px;
    }
    &:hover {
        background-color: ${props => props.theme.sidebar.header.background};
    }
`

const Category = styled(ListItem)`
    padding-top: ${props => props.theme.spacing(3)}px;
    padding-bottom: ${props => props.theme.spacing(3)}px;
    padding-left: ${props => props.theme.spacing(6)}px;
    padding-right: ${props => props.theme.spacing(5)}px;
    font-weight: ${props => props.theme.typography.fontWeightRegular};

    svg {
        color: ${props => props.theme.sidebar.color};
        font-size: 20px;
        width: 20px;
        height: 20px;
        opacity: 0.5;
    }

    &:hover {
        background: rgba(0, 0, 0, 0.08);
    }

    &.${props => props.activeClassName} {
        background-color: ${props => props.theme.sidebar.background};

        span {
            color: ${props => props.theme.sidebar.color};
        }
    }
`

const CategoryText = styled(ListItemText)`
    margin: 0;
    span {
        color: ${props => props.theme.sidebar.color};
        font-size: ${props => props.theme.typography.body1.fontSize}px;
        font-weight: ${props => props.theme.sidebar.category.fontWeight};
        padding: 0 ${props => props.theme.spacing(4)}px;
    }
`

const CategoryIconLess = styled(ExpandLess)`
    color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

const CategoryIconMore = styled(ExpandMore)`
    color: ${props => rgba(props.theme.sidebar.color, 0.5)};
`

const Link = styled(ListItem)`
    padding-left: ${props => props.theme.spacing(15)}px;
    padding-top: ${props => props.theme.spacing(2)}px;
    padding-bottom: ${props => props.theme.spacing(2)}px;

    span {
        color: ${props => rgba(props.theme.sidebar.color, 0.7)};
    }

    &:hover span {
        color: ${props => rgba(props.theme.sidebar.color, 0.9)};
    }

    &.${props => props.activeClassName} {
        background-color: ${props => props.theme.sidebar.background};

        span {
            color: ${props => props.theme.sidebar.color};
        }
    }
`

const LinkText = styled(ListItemText)`
    color: ${props => props.theme.sidebar.color};
    span {
        font-size: ${props => props.theme.typography.body1.fontSize}px;
    }
    margin-top: 0;
    margin-bottom: 0;
`

const LinkBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${props => props.theme.typography.fontWeightBold};
    height: 20px;
    background: ${props => props.theme.sidebar.badge.background};
    color: #fff;
`

const VersionBadge = styled(Chip)`
    font-size: 11px;
    font-weight: ${props => props.theme.typography.fontWeightBold};
    height: 20px;
    position: absolute;
    right: 12px;
    background: ${props => props.theme.sidebar.badge.background};
    color: #fff;
`

const CategoryBadge = styled(LinkBadge)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 42px;
`

const SidebarSection = styled(Typography)`
    color: ${props => props.theme.sidebar.color};
    padding: ${props => props.theme.spacing(4)}px ${props => props.theme.spacing(6)}px
        ${props => props.theme.spacing(1)}px;
    opacity: 0.9;
    display: block;
`

const SidebarFooter = styled.div`
    background-color: ${props => props.theme.sidebar.footer.background} !important;
    padding: ${props => props.theme.spacing(4)}px;
    border-top: 1px solid #fff;
    box-shadow: rgb(0 0 0 / 50%) 0px -6px 6px -6px;
`

function SidebarCategory({ name, icon, classes, isOpen, isCollapsable, badge, ...rest }) {
    return (
        <Category {...rest}>
            {icon}
            <CategoryText>{name}</CategoryText>
            {badge && isCollapsable ? <CategoryBadge label={badge} /> : ''}
            {isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
            {badge && !isCollapsable ? <CategoryBadge label={badge} /> : ''}
        </Category>
    )
}

function SidebarLink({ name, to, badge }) {
    return (
        <Link button dense component={NavLink} exact to={to} activeClassName='active'>
            <LinkText>{name}</LinkText>
            {badge ? <LinkBadge label={badge} /> : ''}
        </Link>
    )
}

function Sidebar({ classes, staticContext, location, ...rest }) {
    const { contextStates } = useContext(StateContext)

    const getRoutes = () => {
        if (contextStates.role === 'propietario') {
            return sidebarRoutesOwner
        } else return routes
    }

    const initOpenRoutes = () => {
        const pathName = location.pathname
        let _routes = {}

        getRoutes().forEach((route, index) => {
            const currentPath = route.children
                ? route.children.find(item => item.path === pathName)?.path
                : route.path === pathName
            const isActive = pathName === currentPath
            const isOpen = false
            const isHome = route.containsHome && pathName === '/' ? true : false
            _routes = Object.assign({}, _routes, {
                [index]: isActive || isOpen || isHome,
            })
        })

        return _routes
    }

    const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes())

    const toggle = index => {
        // Collapse all elements
        Object.keys(openRoutes).forEach(
            item => openRoutes[index] || setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [item]: false }))
        )

        // Toggle selected element
        setOpenRoutes(openRoutes => Object.assign({}, openRoutes, { [index]: !openRoutes[index] }))
    }

    const isSuperAdmin = managePermissions(contextStates.role, 'permissions.adminUsers.crear')
    const isRentappAdmin = contextStates?.userGroup?.includes(roles.admin.RENTAPP)

    return (
        <Drawer variant='permanent' {...rest}>
            <Brand>
                <Link component={NavLink} exact to='/inicio' style={{ justifyContent: 'center', padding: 0 }}>
                    <img src={logo} width={200} alt='Rentapp' />
                </Link>
            </Brand>
            <Scrollbar>
                {isRentappAdmin && isSuperAdmin ? <SelectAccount width={200} /> : null}
                <List disablePadding>
                    <Items>
                        {getRoutes().map((category, index) => {
                            return (
                                <React.Fragment key={index}>
                                    {category.header ? <SidebarSection>{category.header}</SidebarSection> : null}

                                    {category.children ? (
                                        <React.Fragment key={index}>
                                            {manageSectionActions({
                                                role: contextStates.role,
                                                actions: ['ver'],
                                                section: category.children.section,
                                            }) && (
                                                <SidebarCategory
                                                    badge={category.badge}
                                                    isOpen={!openRoutes[index]}
                                                    isCollapsable={true}
                                                    name={category.id}
                                                    icon={category.icon}
                                                    button={true}
                                                    onClick={() => toggle(index)}
                                                />
                                            )}

                                            <Collapse in={openRoutes[index]} timeout='auto' unmountOnExit>
                                                {category.children.map(
                                                    (route, index) =>
                                                        manageSectionActions({
                                                            role: contextStates.role,
                                                            actions: ['ver'],
                                                            section: route.section,
                                                        }) && (
                                                            <SidebarLink
                                                                key={index}
                                                                name={route.name}
                                                                to={route.path}
                                                                icon={route.icon}
                                                                badge={route.badge}
                                                            />
                                                        )
                                                )}
                                            </Collapse>
                                        </React.Fragment>
                                    ) : (
                                        manageSectionActions({
                                            role: contextStates.role,
                                            actions: ['ver'],
                                            section: category.section,
                                        }) && (
                                            <SidebarCategory
                                                isCollapsable={false}
                                                name={category.id}
                                                to={category.path}
                                                activeClassName='active'
                                                component={NavLink}
                                                icon={category.icon}
                                                exact
                                                badge={category.badge}
                                            />
                                        )
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </Items>
                </List>
            </Scrollbar>
            <SidebarFooter>
                <Grid container justifyContent='space-between'>
                    <Grid item xs={6}>
                        <Typography variant='h6' style={{ color: '#fff' }}>
                            Versión
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <VersionBadge label='1.10.0' />
                    </Grid>
                </Grid>
            </SidebarFooter>
        </Drawer>
    )
}

export default withRouter(Sidebar)
